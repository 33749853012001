<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-50">
            <ProgrammeStatusesSelect
              v-model="filters.status"
              multiple
            />
          </div>
          <div class="md-layout-item md-size-50">
            <LevelsSelect
              v-model="filters.course_level_id"
              multiple
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.start_date_from"
              label="Start Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.start_date_to"
              label="Start Date To"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.tuition_finish_date_from"
              label="Tuition Finish Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.tuition_finish_date_to"
              label="Tuition Finish Date To"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getStudents"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import {
  ProgrammeStatusesSelect,
  LevelsSelect,
} from '@/components/Inputs/selects';
import downloadFile from '@/utils/downloadFile';
import moment from 'moment';

export default {
  components: {
    VueTable,
    FormDate,
    LevelsSelect,
    ProgrammeStatusesSelect,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'student.student_number',
            mask: 'Student Number',
            no_format: true,
            sortable: true,
          },
          {
            title: 'student.full_name',
            mask: 'full name',
            sortable: true,
          },
          {
            title: 'student.date_of_birth',
            mask: 'date of birth',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'agent.name',
            mask: 'agent',
            sortable: false,
          },
          {
            title: 'start_date',
            mask: 'start date',
            sortable: false,
            dateFormat: true,
          },
          {
            title: 'finish_date',
            mask: 'finish date',
            sortable: false,
            dateFormat: true,
          },
          {
            title: 'tuition_finish_date',
            mask: 'tuition finish date',
            sortable: false,
            dateFormat: true,
            strong: true,
          },
          {
            title: 'status',
            mask: 'status',
            sortable: false,
          },
          {
            title: 'attendance.attendance_rate',
            mask: 'attendance',
            sortable: false,
          },
          {
            title: 'class_schedule.period',
            mask: 'Period',
            sortable: true,
          },
        ],
        actions: [],
        values: {},
      },
      filters: {
        course_level_id: null,
        start_date_from: null,
        start_date_to: null,
        tuition_finish_date_from: moment().format('YYYY-MM-DD'),
        tuition_finish_date_to: null,
      },
      loading: false,
      params: {},
    };
  },
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getStudents(params) {
      this.params = { ...this.filters, ...params };

      this.loading = true;
      this.request('students_report', 'get', { ...this.params }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    onFilter() {
      this.$refs.vtable.init();
      this.params = { ...this.filters, ...this.params };
    },
    onDownload() {
      this.loading = true;

      this.request('students_report', 'download', { download: true, ...this.params }, ({ data }) => {
        const filename = 'Students_report.xlsx';
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
